import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

import { ToPascalCaseFunctionType } from './models';

export const toPascalCase: ToPascalCaseFunctionType = (str) =>
  startCase(camelCase(str)).replace(/ /g, '');

export const hasLongWords = (text: string, max: number = 15): boolean =>
  text?.split(' ').some((item: string) => item.length > max);

export const compareUrls = (url1: string, url2: string) => {
  if (typeof url1 !== 'string' || typeof url2 !== 'string') return false;

  return url1.replace(/\/$/, '') === url2.replace(/\/$/, '');
};

const stringUtils = {
  toPascalCase,
  hasLongWords,
  compareUrls,
};

export default stringUtils;
